<template>
  <a-spin :tip="loadingTip" :spinning="isloading">
    <div>
      <a-form-model
        ref="articleForm"
        :model="form"
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-form-model-item label="所属分类" prop="categoryId">
          <a-cascader
            placeholder="请选择"
            :options="categoryTree"
            changeOnSelect
            allowClear
            v-model="form.categoryId"
            :fieldNames="{ label: 'name', value: 'id', children: 'children' }"
          />
        </a-form-model-item>
        <a-form-model-item label="资讯标题" prop="title">
          <a-input
            placeholder="请输入"
            v-model="form.title"
            :maxLength="100"
          ></a-input>
        </a-form-model-item>
        <a-row>
          <a-col :span="12">
            <a-form-model-item
              label="资讯类型"
              prop="articleType"
              :label-col="{ span: 8 }"
              :wrapper-col="{ span: 15 }"
            >
              <a-radio-group v-model="form.articleType">
                <template v-for="type in articleTypeList">
                  <a-radio :value="type.type" :key="type.type">
                    {{ type.name }}
                  </a-radio>
                </template>
              </a-radio-group>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item
              label="发布时间"
              prop="publishTime"
              :wrapper-col="{ span: 14 }"
            >
              <a-date-picker v-model="form.publishTime" />
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12">
            <a-form-model-item
              label="标签"
              :label-col="{ span: 8 }"
              :wrapper-col="{ span: 15 }"
            >
              <a-select v-model="form.tag" placeholder="请选择" mode="multiple">
                <template v-for="tag in tagList">
                  <a-select-option :value="tag.itemName" :key="tag.id">
                    {{ tag.itemName }}
                  </a-select-option>
                </template>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item
              label="推荐截止时间"
              prop="cancelTime"
              :wrapper-col="{ span: 14 }"
            >
              <a-date-picker
                v-model="form.cancelTime"
                :disabled-date="disabledDateTime"
              >
                <template slot="renderExtraFooter">
                  <span @click="presetDate(3)">三天后</span>
                  <span @click="presetDate(5)">五天后</span>
                  <span @click="presetDate(7)">一周后</span>
                  <span @click="presetDate(30)">一个月后</span>
                </template>
              </a-date-picker>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12">
            <a-form-model-item
              label="作者"
              :label-col="{ span: 8 }"
              :wrapper-col="{ span: 15 }"
            >
              <a-input
                placeholder="请输入"
                v-model="form.author"
                :maxLength="50"
              ></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="来源" :wrapper-col="{ span: 14 }">
              <a-input
                placeholder="请输入"
                v-model="form.source"
                :maxLength="200"
              ></a-input>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-form-model-item label="概述" prop="summary">
          <a-input
            placeholder="请输入"
            v-model="form.summary"
            type="textarea"
            :maxLength="200"
          ></a-input>
        </a-form-model-item>
        <a-form-model-item
          label="封面图类型"
          prop="pictureType"
          v-if="form.articleType == 1"
        >
          <a-radio-group v-model="form.pictureType">
            <template v-for="type in pictureTypeList">
              <a-radio :value="type.type" :key="type.type">
                {{ type.name }}
              </a-radio>
            </template>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item
          label="封面图"
          v-if="form.articleType == 1 && form.pictureType > 0"
        >
          <a-upload
            accept="image/png,image/jpeg"
            listType="picture-card"
            :action="$api.file.uploadFile"
            :fileList="fileList"
            :beforeUpload="beforeUpload"
            @preview="handlePreview"
            @change="handleFileChange"
          >
            <div
              v-if="
                (form.pictureType != 4 && fileList.length == 0) ||
                (form.pictureType == 4 && fileList.length < 3)
              "
            >
              <a-icon type="plus" />
              <div class="ant-upload-text">Upload</div>
            </div>
          </a-upload>
          <a-modal
            :visible="previewVisible"
            :footer="null"
            @cancel="handleCancel"
          >
            <img alt="example" style="width: 100%" :src="previewImage" />
          </a-modal>
        </a-form-model-item>
        <a-form-model-item label="视频" v-if="form.articleType == 2">
          <a-upload
            accept="video/mp4"
            :action="$api.file.uploadFile"
            :fileList="fileList"
            :beforeUpload="beforeUpload"
            @change="handleFileChange"
          >
            <a-button> <a-icon type="upload" /> 上传 </a-button>
          </a-upload>
        </a-form-model-item>
        <a-form-model-item label="图集" v-if="form.articleType == 3">
          <a-upload
            accept="image/png,image/jpeg"
            :action="$api.file.uploadFile"
            :showUploadList="false"
            :beforeUpload="beforeUpload"
            @change="handleImgListChange"
          >
            <a-button :loading="imgUploading">
              <a-icon type="upload" /> 上传
            </a-button>
          </a-upload>
        </a-form-model-item>
        <a-form-model-item label="图集列表" v-if="form.articleType == 3">
          <a-table
            rowKey="sortNo"
            bordered
            size="small"
            :columns="imgTable.columns"
            :pagination="false"
            :dataSource="form.imgList"
          >
            <template slot="path" slot-scope="path">
              <img style="width: 160px" :src="path" />
            </template>
            <template slot="info" slot-scope="info">
              <editable-cell
                :text="info.introduce"
                @change="onCellChange(info.sortNo, 'introduce', $event)"
              />
            </template>
            <span slot="action" slot-scope="info">
              <pxkeji-btn
                name="上移"
                size="small"
                type="link"
                @click="handleUp(info.sortNo)"
                v-if="info.sortNo > 1"
              />
              <pxkeji-btn
                name="下移"
                size="small"
                type="link"
                @click="handleDown(info.sortNo)"
                v-if="info.sortNo < form.imgList.length"
              />
              <a-popconfirm
                title="确定要删除吗?"
                @confirm="() => removeImg(info.sortNo)"
              >
                <pxkeji-btn
                  name="刪除"
                  size="small"
                  type="link"
                  style="color: #ff4d4f"
                />
              </a-popconfirm>
            </span>
          </a-table>
        </a-form-model-item>
        <a-form-model-item
          label="内容"
          prop="content"
          v-if="form.articleType == 1"
        >
          <vue-ueditor-wrap
            v-model="form.content"
            :config="myConfig"
          ></vue-ueditor-wrap>
        </a-form-model-item>
        <a-row>
          <a-col :span="8">
            <a-form-model-item
              label="是否推荐"
              :label-col="{ span: 12 }"
              :wrapper-col="{ span: 8 }"
            >
              <a-switch
                v-model="form.recommend"
                checked-children="是"
                un-checked-children="否"
              />
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item
              label="状态"
              :label-col="{ span: 4 }"
              :wrapper-col="{ span: 8 }"
            >
              <a-switch
                v-model="form.status"
                checked-children="启用"
                un-checked-children="禁用"
              />
            </a-form-model-item>
          </a-col>
        </a-row>

        <a-form-model-item :wrapper-col="{ span: 19, offset: 4 }">
          <pxkeji-btn
            name="审核"
            type="primary"
            @click="handleSubmit()"
            v-if="infos.type == 3"
          />
          <pxkeji-btn
            name="提交"
            type="primary"
            @click="handleSubmit(1)"
            v-if="infos.type != 3"
          />
          <!-- <pxkeji-btn
            name="保存"
            @click="handleSubmit(0)"
            class="cancelBtn"
            v-if="infos.type != 3"
          /> -->
          <pxkeji-btn
            name="取消"
            type="default"
            @click="cancelClick"
            class="cancelBtn"
          />
        </a-form-model-item>
      </a-form-model>
    </div>
  </a-spin>
</template>

<script>
let _this;
import VueUeditorWrap from "vue-ueditor-wrap";
const EditableCell = {
  template: `
      <div class="editable-cell">
        <div v-if="editable" class="editable-cell-input-wrapper">
          <a-textarea
            size="small"
            :value="value"
            @change="handleChange"
            @pressEnter="check"
            :suffix=suffixTxt
          />
          <a-icon
            type="check"
            class="editable-cell-icon-check"
            @click="check"
          />
        </div>
        <div v-else class="editable-cell-text-wrapper">
          {{ value || ' ' }}
          <a-icon type="edit" class="editable-cell-icon" @click="edit" />
        </div>
      </div>
    `,
  props: {
    text: String,
    suffixTxt: String,
  },
  data() {
    return {
      value: this.text,
      editable: false,
    };
  },
  watch: {
    text(val) {
      this.value = val;
    },
  },
  methods: {
    handleChange(e) {
      const value = e.target.value;
      this.value = value;
    },
    check() {
      this.editable = false;
      this.$emit("change", this.value);
    },
    edit() {
      this.editable = true;
    },
  },
};
export default {
  props: {
    infos: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    let checkContent = (rule, value, callback) => {
      if (value) {
        callback();
      } else {
        callback(new Error("请输入资讯内容"));
      }
    };
    let checkAuditStatus = (rule, value, callback) => {
      if (value) {
        if (value != 2 && value != 3) {
          callback(new Error("请选择审核结果"));
        } else {
          callback();
        }
      } else {
        callback(new Error("请选择审核结果"));
      }
    };
    return {
      isloading: false,
      loadingTip: "加载中...",
      labelCol: { span: 4 },
      wrapperCol: { span: 17 },
      categoryTree: [],
      articleTypeList: [],
      pictureTypeList: [],
      tagList: [],
      form: {
        categoryId: [],
        articleType: 1,
        pictureType: 0,
        pictures: [],
        status: true,
        recommend: false,
        content: "",
        imgList: [],
        tag: [],
        publishTime: null,
        cancelTime: null,
      },
      rules: {
        categoryId: [
          { required: true, message: "请选择所属分类", trigger: "change" },
        ],
        title: [{ required: true, message: "请输入资讯标题", trigger: "blur" }],
        articleType: [
          { required: true, message: "请选择资讯类型", trigger: "change" },
        ],
        pictureType: [
          { required: true, message: "请选择封面图类型", trigger: "change" },
        ],
        content: [
          { required: true, message: "请输入资讯内容", trigger: "change" },
          { validator: checkContent, trigger: "change" },
        ],
        summary: [
          { required: true, message: "请输入资讯概述", trigger: "blur" },
        ],
        auditStatus: [
          { required: true, message: "请选择审核结果", trigger: "change" },
          { validator: checkAuditStatus, trigger: "change" },
        ],
      },
      myConfig: {
        autoHeightEnabled: false,
        initialFrameHeight: 600,
        wordCount: true,
        maximumWords: 100000,
        initialFrameWidth: "100%",
        serverUrl: this.$api.file.uploadUpFile,
        UEDITOR_HOME_URL: "/UEditor/",
        toolbars: this.$commen.editorConfig.toolbars,
      },
      previewVisible: false,
      previewImage: "",
      fileList: [],
      imgUploading: false,
      imgTable: {
        columns: [
          {
            dataIndex: "sortNo",
            title: "序号",
            width: 50,
            align: "center",
          },
          {
            dataIndex: "path",
            title: "图片",
            width: 180,
            align: "center",
            scopedSlots: { customRender: "path" },
          },
          {
            key: "introduce",
            title: "图片简介",
            align: "center",
            scopedSlots: { customRender: "info" },
          },
          {
            key: "action",
            title: "操作",
            width: 180,
            align: "center",
            scopedSlots: { customRender: "action" },
          },
        ],
      },
    };
  },
  components: {
    VueUeditorWrap,
    EditableCell,
  },
  mounted() {
    _this = this;
    _this.getArticleTypes();
    _this.getPictureTypes();
    _this.getCategoryTree();
    _this.getTagList();
    if (_this.infos.id != 0) {
      //编辑
      _this.getInfo();
    }
  },
  methods: {
    disabledDateTime(val) {
      const startVal = _this.form.publishTime
        ? _this.$moment(_this.form.publishTime, "YYYY-MM-DD")
        : _this.$moment();
      if (!val || !startVal) {
        return false;
      }
      return val.valueOf() <= startVal.valueOf();
    },
    presetDate(num) {
      let startDate = _this.form.publishTime;
      if (startDate) {
        startDate = _this.$moment(startDate, "YYYY-MM-DD");
      } else {
        startDate = _this.$moment();
      }
      console.log("startDate", startDate);
      _this.form.cancelTime = startDate.add(num, "days");
    },
    async getArticleTypes() {
      let result = await _this.$api.article.getArticleTypes();
      if (result.errorCode == _this.$msg.responseCode.success) {
        _this.articleTypeList = result.data;
      } else {
        _this.$message.error(result.errorMsg);
      }
    },
    async getPictureTypes() {
      let result = await _this.$api.article.getPictureTypes();
      if (result.errorCode == _this.$msg.responseCode.success) {
        _this.pictureTypeList = result.data;
      } else {
        _this.$message.error(result.errorMsg);
      }
    },
    async getCategoryTree() {
      let result = await _this.$api.articlecategory.getTree(1, true);
      if (result.errorCode == _this.$msg.responseCode.success) {
        _this.categoryTree = result.data;
      } else {
        _this.$message.error(result.errorMsg);
      }
    },
    /**获取标签列表 */
    async getTagList() {
      let result = await _this.$api.dictionaryItem.tree(4);
      if (result.errorCode == _this.$msg.responseCode.success) {
        _this.tagList = result.data;
      } else {
        _this.$message.error(result.errorMsg);
      }
    },
    /**回显栏目 */
    async getParent(id) {
      let result = await _this.$api.articlecategory.getParents(id);
      return result;
    },
    /**获取详情 */
    getInfo() {
      _this.isloading = true;
      _this.$api.article
        .getById(_this.infos.id)
        .then((res) => {
          if (res.errorCode == this.$msg.responseCode.success) {
            if (res.data.pictures) {
              res.data.pictures = res.data.pictures.split(",");
              let type = "pictures";
              if (res.data.articleType == 2) {
                type = "video";
              }
              res.data.pictures.forEach(function (item, index) {
                _this.fileList.push({
                  name: `${type}_${index + 1}`,
                  response: { data: { name: item, path: item } },
                  uid: `${type}_${index + 1}`,
                  thumbUrl: item,
                });
              });
            } else {
              res.data.pictures = [];
            }
            if (res.data.tag) {
              res.data.tag = res.data.tag.split(",");
            }
            _this.$api.articlecategory
              .getParents(res.data.categories.map((item) => item.id)[0])
              .then((result) => {
                _this.form = {
                  ...res.data,
                  categoryId: result.data,
                  publishTime: res.data.publishTime
                    ? res.data.publishTime
                    : null,
                  cancelTime: res.data.cancelTime ? res.data.cancelTime : null,
                };
                _this.form.recommend = _this.$commen.changeIntBoolean(
                  res.data.recommend
                );
                _this.form.status = _this.$commen.changeIntBoolean(
                  res.data.status
                );
                _this.isloading = false;
              });
          } else {
            _this.$message.error(res.errorMsg);
          }
        })
        .catch(() => {
          _this.isloading = false;
        });
    },
    /**提交审核 */
    handleSubmit() {
      _this.$refs.articleForm.validate((valid) => {
        if (valid) {
          _this.isloading = true;
          _this.loadingTip = "保存中...";
          if (_this.form.pictureType > 0 && _this.form.pictures.length == 0) {
            _this.$message.error("请上传封面图");
            _this.isloading = false;
            return false;
          }
          if (_this.form.pictureType == 4 && _this.form.pictures.length < 3) {
            _this.$message.error("请上传三张封面图");
            _this.isloading = false;
            return false;
          }
          if (_this.form.articleType == 3 && _this.form.imgList.length == 0) {
            _this.$message.error("请上传图集图片");
            _this.isloading = false;
            return false;
          }
          let formData = { ..._this.form, categories: [] };

          formData.categories.push({
            id: formData.categoryId[formData.categoryId.length - 1],
          });
          delete formData.categoryId;
          formData.recommend = _this.$commen.changeBooleanInt(
            _this.form.recommend
          );

          formData.status = _this.$commen.changeBooleanInt(_this.form.status);

          formData.pictures = _this.form.pictures.join();

          formData.tag = formData.tag ? formData.tag.join(",") : "";
          formData.auditStatus = 2;
          if (_this.form.articleType == 3) {
            formData.imgListJSON = JSON.stringify(_this.form.imgList);
            delete formData.imgList;
          }
          if (_this.form.articleType == 1) {
            delete formData.imgList;
          }

          if (_this.infos.id != 0) {
            formData.id = _this.infos.id;
          }

          if (_this.infos.type == 3) {
            // 审核
            _this.$api.article
              .audit(_this.infos.id, formData.auditStatus, formData.auditRemark)
              .then((res) => {
                if (res.errorCode == _this.$msg.responseCode.success) {
                  _this.$message.success(res.errorMsg);
                  _this.$emit("callbackMethod");
                } else {
                  _this.$message.error(res.errorMsg);
                }
                _this.isloading = false;
              })
              .catch(() => {
                _this.isloading = false;
              });
          } else {
            // 添加、修改
            _this.$api.article
              .saveOrUpdate(formData)
              .then((res) => {
                if (res.errorCode == _this.$msg.responseCode.success) {
                  _this.$message.success(res.errorMsg);
                  _this.$emit("callbackMethod");
                } else {
                  _this.$message.error(res.errorMsg);
                }
                _this.isloading = false;
              })
              .catch(() => {
                _this.isloading = false;
              });
          }
        } else {
          return false;
        }
      });
    },
    handleCancel() {
      _this.previewVisible = false;
    },
    handlePreview(file) {
      _this.previewImage = file.url || file.thumbUrl;
      _this.previewVisible = true;
    },
    beforeUpload(file) {
      if (_this.form.articleType == 1 || _this.form.articleType == 3) {
        let isJPGPNG = false;
        if (file.type === "image/jpeg" || file.type === "image/png") {
          isJPGPNG = true;
        } else {
          isJPGPNG = false;
          _this.$message.error("只能上传jpg/png格式的文件!");
        }
        let isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
          _this.$message.error("文件不能超过2MB!");
        }
        return isJPGPNG && isLt2M;
      } else if (_this.form.articleType == 2) {
        let mp4 = false;
        if (file.type === "video/mp4") {
          mp4 = true;
        } else {
          mp4 = false;
          _this.$message.error("只能上传mp4格式的文件!");
        }
        let isLt = file.size / 1024 / 1024 < 500;
        if (!isLt) {
          _this.$message.error("文件不能超过500MB!");
        }
        return mp4 && isLt;
      } else {
        return false;
      }
    },
    handleFileChange(fileInfo) {
      _this.fileList = fileInfo.fileList;
      if (fileInfo.file.status == "done") {
        if (
          fileInfo.file.response.errorCode != _this.$msg.responseCode.success
        ) {
          _this.$message.error("上传失败");
        } else {
          _this.form.pictures.push(fileInfo.file.response.data.path);
        }
      } else if (fileInfo.file.status == "removed") {
        _this.form.pictures.splice(
          _this.form.pictures.indexOf(fileInfo.file.thumbUrl),
          1
        );
      } else if (fileInfo.file.status == "error") {
        _this.$message.error("上传失败");
      }
    },
    handleImgListChange(fileInfo) {
      if (fileInfo.file.status == "uploading" && !_this.imgUploading) {
        _this.imgUploading = true;
      } else if (fileInfo.file.status == "done") {
        if (
          fileInfo.file.response.errorCode != _this.$msg.responseCode.success
        ) {
          _this.$message.error("上传失败");
        } else {
          _this.$message.success("上传成功");
          let no = _this.form.imgList.length;
          _this.form.imgList.push({
            path: fileInfo.file.response.data.path,
            introduce: "",
            sortNo: no + 1,
          });
          _this.imgUploading = false;
        }
      } else if (fileInfo.file.status == "error") {
        _this.$message.error("上传失败");
        _this.imgUploading = false;
      }
    },
    onCellChange(sortNo, dataIndex, value) {
      let imgTextList = _this.form.imgList;
      let target = imgTextList.find((item) => item.sortNo == sortNo);
      if (target) {
        target[dataIndex] = value;
        _this.form.imgList = imgTextList;
      }
    },
    removeImg(sortNo) {
      let imgTextList = _this.form.imgList;
      _this.form.imgList = imgTextList.filter((item) => item.sortNo != sortNo);
      _this.form.imgList.map((item) => {
        if (item.sortNo > sortNo) {
          item.sortNo = item.sortNo - 1;
        }
      });
    },
    upOrDown(index1, index2) {
      let curr = _this.form.imgList.splice(index1, 1, {
        ..._this.form.imgList[index2],
        sortNo: index1 + 1,
      })[0];
      _this.form.imgList.splice(index2, 1, { ...curr, sortNo: index2 + 1 });
    },
    handleUp(sortNo) {
      if (sortNo > 1) {
        _this.upOrDown(sortNo - 1, sortNo - 2);
      }
    },
    handleDown(sortNo) {
      if (sortNo < _this.form.imgList.length) {
        _this.upOrDown(sortNo - 1, sortNo);
      }
    },
    /**取消 */
    cancelClick() {
      _this.$emit("callbackMethod");
    },
  },
};
</script>
<style>
.checkWrapper {
  padding-top: 20px;
  border-top: 1px #d9d9d9 solid;
}
.editable-cell {
  position: relative;
}

.editable-cell-input-wrapper,
.editable-cell-text-wrapper {
  padding-right: 24px;
}

.editable-cell-text-wrapper {
  padding: 5px 24px 5px 5px;
}

.editable-cell-icon,
.editable-cell-icon-check {
  position: absolute;
  right: 0;
  width: 20px;
  cursor: pointer;
}

.editable-cell-icon {
  line-height: 18px;
  /* display: none; */
}

.editable-cell-icon-check {
  line-height: 28px;
}

.editable-cell:hover .editable-cell-icon {
  display: inline-block;
}

.editable-cell-icon:hover,
.editable-cell-icon-check:hover {
  color: #108ee9;
}

.editable-add-btn {
  margin-bottom: 8px;
}
.ant-calendar-footer-extra span {
  margin: 0 9px;
  cursor: pointer;
}
</style>