<template>
  <div>
    <div class="pxkeji-right-box">
      <a-form class="ant-advanced-search-form">
        <a-row :gutter="24">
          <a-col :span="6">
            <a-form-item label="资讯标题">
              <a-input placeholder="请输入" v-model="search.title" />
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="所属分类">
              <a-cascader
                allowClear
                changeOnSelect
                :fieldNames="{
                  label: 'name',
                  value: 'id',
                  children: 'children',
                }"
                :options="categoryList"
                v-model="search.categoryIds"
                placeholder="请选择"
              />
            </a-form-item>
          </a-col>
          <a-col :span="4">
            <a-form-item label="状态">
              <a-select v-model="search.status" placeholder="请选择" allowClear>
                <a-select-option value="">全部</a-select-option>
                <a-select-option value="0">已禁用</a-select-option>
                <a-select-option value="1">已启用</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="4">
            <a-form-item>
              <a-button type="primary" @click="searchList">搜索</a-button>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
      <div>
        <pxkeji-btn
          name="添加"
          class="pxkeji_basic_btn"
          @click="showInfo(0, 1)"
          type="primary"
          :roles="['文章-文章添加']"
        />
        <pxkeji-btn
          name="批量启用"
          :disabled="selectRows.length == 0"
          @click="batchUpdateStatus(1)"
          class="pxkeji_basic_btn"
          :roles="['文章-文章修改状态']"
        />
        <pxkeji-btn
          name="批量禁用"
          :disabled="selectRows.length == 0"
          @click="batchUpdateStatus(0)"
          class="pxkeji_basic_btn"
          :roles="['文章-文章修改状态']"
        />
        <!-- <pxkeji-btn
          name="批量审核"
          :disabled="selectRows.length == 0"
          @click="batchAudit()"
          class="pxkeji_basic_btn"
          v-if="search.auditStatus == 1"
        /> -->
        <pxkeji-btn
          name="批量删除"
          :disabled="selectRows.length == 0"
          :type="`danger`"
          @click="batchDelete"
          class="pxkeji_basic_btn"
          :roles="['文章-文章删除']"
        />
        <span style="margin-left: 8px">
          <template v-if="selectRows.length > 0">
            {{ `已选择 ${selectRows.length} 项` }}
            <a-button type="link" @click="cancelSelect"> 取消选择 </a-button>
          </template>
        </span>
      </div>
    </div>

    <div>
      <a-table
        rowKey="id"
        size="small"
        bordered
        :columns="list.columns"
        :pagination="list.pagination"
        @change="onPageChange"
        :dataSource="list.datas"
        :loading="isloading"
        :rowSelection="{
          type: 'checkbox',
          columnWidth: 40,
          onChange: onRowChange,
          selectedRowKeys: selectRows,
        }"
        :customRow="customRow"
        :rowClassName="setRowClassName"
        :locale="{ emptyText: '暂无数据' }"
        :scroll="{ y: list.tableHeight }"
      >
        <template slot="articleType" slot-scope="articleType">
          <span v-if="articleType == 1">文章</span>
          <span v-if="articleType == 2">视频</span>
          <span v-if="articleType == 3">图集</span>
        </template>
        <template slot="pictureType" slot-scope="pictureType">
          <span v-if="pictureType == 0">无图</span>
          <span v-if="pictureType == 1">单图大图</span>
          <span v-if="pictureType == 2">单图居左</span>
          <span v-if="pictureType == 3">单图居右</span>
          <span v-if="pictureType == 4">三图</span>
        </template>
        <template slot="top" slot-scope="top">
          <a-switch
            checked-children="是"
            un-checked-children="否"
            :loading="switchLoading"
            :checked="top.isTop == 1"
            @change="onSwitchChange(top, $event)"
          />
        </template>
        <template slot="status" slot-scope="status">
          <a-tag v-if="status == 1" color="#87d068">启用</a-tag>
          <a-tag v-else color="#f50">禁用</a-tag>
        </template>
        <span slot="action" slot-scope="action">
          <pxkeji-btn
            name="编辑"
            @click="showInfo(action.id, 2)"
            size="small"
            type="primary"
            :roles="['文章-文章修改']"
          />
          <pxkeji-btn
            name="预览"
            size="small"
            type="default"
            @click="showContent(action.id)"
            v-if="action.articleType == 1"
          />
          <a-popconfirm
            @confirm="updateStatus(action.id, 1)"
            @cancel="modalClose"
          >
            <div slot="title">是否确认启用此文章？</div>
            <a-icon slot="icon" type="question-circle-o" />
            <pxkeji-btn
              name="启用"
              size="small"
              type="default"
              v-if="action.status == 0"
              :roles="['文章-文章修改状态']"
            />
          </a-popconfirm>
          <a-popconfirm
            @confirm="updateStatus(action.id, 0)"
            @cancel="modalClose"
          >
            <div slot="title">是否确认禁用此文章？</div>
            <a-icon slot="icon" type="question-circle-o" />
            <pxkeji-btn
              name="禁用"
              size="small"
              type="default"
              v-if="action.status == 1"
              :roles="['文章-文章修改状态']"
            />
          </a-popconfirm>
          <a-dropdown>
            <a-menu slot="overlay">
              <a-menu-item key="1">
                <pxkeji-btn
                  name="上移"
                  size="small"
                  @click="upOrDown(action.id, 1)"
                  v-if="action.auditStatus == 2"
                  :roles="['文章-文章修改排序']"
                />
              </a-menu-item>
              <a-menu-item key="2">
                <pxkeji-btn
                  name="下移"
                  size="small"
                  @click="upOrDown(action.id, 0)"
                  v-if="action.auditStatus == 2"
                  :roles="['文章-文章修改排序']"
                />
              </a-menu-item>
              <a-menu-item key="4">
                <pxkeji-btn
                  name="推送"
                  size="small"
                  @click="doPush(action.id)"
                  v-if="action.auditStatus == 2"
                />
              </a-menu-item>
              <a-menu-item key="3">
                <a-popconfirm
                  @confirm="delInfo(action.id)"
                  @cancel="modalClose"
                >
                  <div slot="title">是否确认删除此文章？</div>
                  <a-icon slot="icon" type="question-circle-o" />
                  <pxkeji-btn
                    name="删除"
                    size="small"
                    type="danger"
                    :loading="isloading"
                    :roles="['文章-文章删除']"
                  />
                </a-popconfirm>
              </a-menu-item>
            </a-menu>
            <a-button size="small" type="link"> ... </a-button>
          </a-dropdown>
        </span>
      </a-table>
    </div>

    <a-modal
      :title="addedit.title"
      v-model="addedit.visible"
      width="75%"
      :footer="false"
      destroyOnClose
      :maskClosable="false"
      :afterClose="modalClose"
    >
      <ArticleInfo @callbackMethod="save" :sta="false" :infos="addedit.info" />
    </a-modal>
    <a-modal
      title="批量审核"
      v-model="auditInfo.visible"
      width="500px"
      :footer="false"
      destroyOnClose
      :maskClosable="false"
      :afterClose="modalClose"
    >
      <a-form-model
        ref="auditForm"
        :model="auditInfo"
        :rules="rules"
        :label-col="{ span: 5 }"
        :wrapper-col="{ span: 17 }"
      >
        <a-form-model-item label="审核结果" prop="auditStatus">
          <a-radio-group v-model="auditInfo.auditStatus">
            <a-radio :value="2"> 审核通过 </a-radio>
            <a-radio :value="3"> 审核退回 </a-radio>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item label="审核意见">
          <a-input
            placeholder="请输入"
            v-model="auditInfo.auditRemark"
            type="textarea"
            :maxLength="200"
          ></a-input>
        </a-form-model-item>
        <a-form-model-item :wrapper-col="{ span: 19, offset: 4 }">
          <pxkeji-btn name="审核" type="primary" @click="toAudit" />
          <pxkeji-btn
            name="取消"
            type="default"
            @click="cancelClick"
            class="cancelBtn"
          />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
    <a-modal
      title="新闻预览"
      v-model="showVisible"
      width="1190px"
      :footer="false"
      destroyOnClose
      :afterClose="modalClose"
    >
      <div
        style="
          font-size: 16px;
          font-weight: 600;
          margin-bottom: 12px;
          text-align: center;
        "
      >
        {{ newsTitle }}
      </div>
      <div v-html="newsContent"></div>
    </a-modal>
  </div>
</template>
<script>
import ArticleInfo from "./articleInfo.vue"; //新增编辑

const columns = [
  {
    dataIndex: "title",
    align: "left",
    title: "资讯标题",
    ellipsis: true,
  },
  {
    dataIndex: "categoryName",
    align: "center",
    title: "所属分类",
    ellipsis: true,
  },
  {
    dataIndex: "articleType",
    align: "center",
    title: "资讯类型",
    width: 110,
    scopedSlots: { customRender: "articleType" },
  },
  {
    dataIndex: "pictureType",
    align: "center",
    title: "封面图类型",
    width: 120,
    scopedSlots: { customRender: "pictureType" },
  },
  {
    dataIndex: "tag",
    align: "center",
    title: "标签",
    ellipsis: true,
    width: 160,
  },
  {
    key: "top",
    align: "center",
    title: "是否置顶",
    width: 100,
    scopedSlots: { customRender: "top" },
  },
  {
    dataIndex: "status",
    align: "center",
    title: "状态",
    width: 100,
    scopedSlots: { customRender: "status" },
  },
  {
    title: "操作",
    key: "action",
    align: "center",
    width: 220,
    scopedSlots: { customRender: "action" },
  },
];
let _this;
export default {
  name: "successArticleList",
  data() {
    let checkAuditStatus = (rule, value, callback) => {
      if (value) {
        if (value != 2 && value != 3) {
          callback(new Error("请选择审核结果"));
        } else {
          callback();
        }
      } else {
        callback(new Error("请选择审核结果"));
      }
    };
    return {
      list: {
        columns, //表头
        datas: [], //数据
        pagination: {
          showQuickJumper: true,
          hideOnSinglePage: true,
          showSizeChanger: true,
          total: 0,
          current: 1,
          pageSize: 10,
          showTotal: (total) => `共 ${total} 条`,
        },
        tableHeight: window.innerHeight - 295,
      },
      categoryList: [],
      addedit: {
        title: "",
        info: {},
        visible: false,
      },
      search: {
        categoryIds: [],
        title: "",
        auditStatus: 2,
      },
      selectRows: [], //选中项编号
      isloading: false,
      switchLoading: false,
      auditInfo: {
        visible: false,
        auditStatus: 0,
        auditRemark: "",
      },
      rules: {
        auditStatus: [
          { required: true, message: "请选择审核结果", trigger: "change" },
          { validator: checkAuditStatus, trigger: "change" },
        ],
      },
      showVisible: false,
      newsTitle: "",
      newsContent: "",
      selectRowId: "",
    };
  },
  components: {
    ArticleInfo,
  },
  mounted: function () {
    _this = this;
    if (window.innerWidth >= 1600) {
      _this.list.pagination.pageSize = 20;
    }
    window.onresize = () => {
      _this.list.tableHeight = window.innerHeight - 295;
      if (window.innerWidth >= 1600) {
        _this.list.pagination.pageSize = 20;
      } else {
        _this.list.pagination.pageSize = 10;
      }
    };
    _this.getCategoryTree();
    _this.GetList();
  },
  methods: {
    async getCategoryTree() {
      let result = await _this.$api.articlecategory.getTree(1, true);
      if (result.errorCode == _this.$msg.responseCode.success) {
        _this.categoryList = result.data;
      } else {
        _this.$message.error(result.errorMsg);
      }
    },
    doPush(id) {
      _this.isloading = true;
      _this.$api.article
        .pushMessage(id)
        .then((res) => {
          _this.isloading = false;
          if (res.errorCode == _this.$msg.responseCode.success) {
            _this.$message.success(res.errorMsg);
          } else {
            _this.$message.error(res.errorMsg);
          }
        })
        .catch(() => {
          _this.isloading = false;
        });
    },
    GetList() {
      //获取列表
      _this.isloading = true;
      _this.cancelSelect();
      let queryData = { ..._this.search };
      queryData.categoryId =
        queryData.categoryIds[queryData.categoryIds.length - 1];
      delete queryData.categoryIds;
      _this.$api.article
        .getPageList(
          _this.list.pagination.current,
          _this.list.pagination.pageSize,
          queryData
        )
        .then((res) => {
          if (res.errorCode == _this.$msg.responseCode.success) {
            _this.list.datas = res.data.records;
            _this.list.pagination.total = Number(res.data.total);
          } else {
            _this.$message.error(res.errorMsg);
          }
          _this.isloading = false;
        })
        .catch(() => {
          _this.isloading = false;
        });
    },
    onPageChange(page) {
      _this.list.pagination.current = page.current;
      _this.list.pagination.pageSize = page.pageSize;
      _this.GetList();
    },
    onRowChange(selectedRowKeys) {
      _this.selectRows = selectedRowKeys;
    },
    cancelSelect() {
      _this.selectRows = [];
    },
    customRow(record) {
      return {
        on: {
          click: () => {
            _this.selectRowId = record.id;
          },
        },
      };
    },
    setRowClassName(record) {
      return _this.selectRowId == record.id ? "selectRow" : "";
    },
    searchList() {
      _this.list.pagination.current = 1;
      _this.GetList();
    },
    /*审核查看 */
    showInfo(id, type) {
      //打开弹出框
      _this.addedit.visible = true;
      if (type == 1) {
        _this.addedit.title = "添加";
      } else if (type == 2) {
        _this.addedit.title = "编辑";
      } else if (type == 3) {
        _this.addedit.title = "审核";
      }
      _this.addedit.info = { id, type };
    },
    showContent(id) {
      _this.showVisible = true;
      _this.$api.article.getById(id).then((res) => {
        if (res.errorCode == _this.$msg.responseCode.success) {
          _this.newsTitle = res.data.title;
          _this.newsContent = res.data.content;
        } else {
          _this.$message.error(res.errorMsg);
        }
      });
    },
    onSwitchChange(info) {
      if (info.isTop == 1) {
        _this.cancelTop(info.id);
      } else {
        _this.top(info.id);
      }
    },
    top(id) {
      _this.isloading = true;
      _this.$api.article
        .top(id)
        .then((res) => {
          if (res.errorCode == _this.$msg.responseCode.success) {
            _this.$message.success(res.errorMsg);
            _this.GetList();
          } else {
            _this.$message.error(res.errorMsg);
            _this.isloading = false;
          }
        })
        .catch(() => {
          _this.isloading = false;
        });
    },
    cancelTop(id) {
      _this.isloading = true;
      _this.$api.article
        .cancelTop(id)
        .then((res) => {
          if (res.errorCode == _this.$msg.responseCode.success) {
            _this.$message.success(res.errorMsg);
            _this.GetList();
          } else {
            _this.$message.error(res.errorMsg);
            _this.isloading = false;
          }
        })
        .catch(() => {
          _this.isloading = false;
        });
    },
    batchAudit() {
      _this.auditInfo.visible = true;
    },
    toAudit() {
      _this.$refs.auditForm.validate((valid) => {
        if (valid) {
          _this.$api.article
            .audit(
              _this.selectRows.join(),
              _this.auditInfo.auditStatus,
              _this.auditInfo.auditRemark
            )
            .then((res) => {
              if (res.errorCode == _this.$msg.responseCode.success) {
                _this.$message.success(res.errorMsg);
                _this.GetList();
                _this.cancelClick();
              } else {
                _this.$message.error(res.errorMsg);
              }
            })
            .catch(() => {});
        } else {
          return false;
        }
      });
    },
    cancelClick() {
      _this.$refs.auditForm.resetFields();
      _this.auditInfo.visible = false;
    },
    updateStatus(id, status) {
      _this.isloading = true;
      _this.$api.article
        .updateStatus(id, status)
        .then((res) => {
          if (res.errorCode == _this.$msg.responseCode.success) {
            _this.$message.success(res.errorMsg);
            _this.GetList();
          } else {
            _this.$message.error(res.errorMsg);
            _this.isloading = false;
          }
        })
        .catch(() => {
          _this.isloading = false;
        });
    },
    /** 批量提交 */
    batchUpdateStatus(status) {
      let msg = "禁用";
      if (status == 1) {
        msg = "启用";
      }
      _this.$confirm({
        title: "提示",
        content: `确定要批量${msg}选择的文章吗?`,
        okText: "确定",
        cancelText: "取消",
        onOk() {
          _this.updateStatus(_this.selectRows.join(), status);
        },
        onCancel() {},
      });
    },
    upOrDown(id, up) {
      _this.isloading = true;
      _this.$api.article
        .upOrDown(id, up)
        .then((res) => {
          if (res.errorCode == _this.$msg.responseCode.success) {
            _this.$message.success(res.errorMsg);
            _this.GetList();
          } else {
            _this.$message.error(res.errorMsg);
            _this.isloading = false;
          }
        })
        .catch(() => {
          _this.isloading = false;
        });
    },
    /**删除 */
    delInfo(id) {
      _this.isloading = true;
      _this.$api.article
        .removeByIds(id)
        .then((res) => {
          if (res.errorCode == _this.$msg.responseCode.success) {
            _this.$message.success(res.errorMsg);
            _this.GetList();
          } else {
            _this.$message.error(res.errorMsg);
            _this.isloading = false;
          }
        })
        .catch(() => {
          _this.isloading = false;
        });
    },
    /** 批量删除 */
    batchDelete() {
      _this.$confirm({
        title: "提示",
        content: "确定要批量删除选择的文章吗?",
        okText: "确定",
        cancelText: "取消",
        onOk() {
          _this.delInfo(_this.selectRows.join());
        },
        onCancel() {},
      });
    },
    modalClose() {
      setTimeout(() => {
        _this.selectRowId = "";
      }, 3000);
    },
    save() {
      _this.addedit.visible = false;
      _this.GetList();
    },
  },
};
</script>

<style>
.ant-advanced-search-form .ant-form-item {
  display: flex;
}

.ant-advanced-search-form .ant-form-item-control-wrapper {
  flex: 1;
}

#components-form-demo-advanced-search .ant-form {
  max-width: none;
}

#components-form-demo-advanced-search .search-result-list {
  margin-top: 16px;
  border: 1px dashed #e9e9e9;
  border-radius: 6px;
  background-color: #fafafa;
  min-height: 200px;
  text-align: center;
  padding-top: 80px;
}
</style>